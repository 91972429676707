/* eslint-disable react/jsx-newline */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import imgDesktopPlanning from '@assets/images/desktop-planning.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'
import gradients from '@assets/svg/gradients-center.svg'
import { Box, Center, Flex, Heading, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import Section from '@components/core/Section'
import { CtaButton } from '@components/ui/Button'
import { Card, GradientCard, HorizontalGridCard } from '@components/ui/Card'
import { HorizontalCarousel, VerticalCarousel } from '@components/ui/Carousel'
import { MobileDesktopDisplay } from '@components/ui/Display'
import Flow from '@components/ui/Flow'
import IconItemList from '@components/ui/IconItemList'
import IconLink from '@components/ui/IconLink'
import MenuSticky from '@components/ui/MenuSticky'
import Quote from '@components/ui/Quote'
import { features, functionalities, horizontalCarouselContent, verticalCarouselContent } from '@data/cliniques'
import { CALENDLY_URL } from '@helpers/url'
import { graphql } from 'gatsby'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { GrCheckmark } from 'react-icons/gr'
import { v4 as uuidv4 } from 'uuid'

const ClinicalPage = () => {
    const [isTablet, isDesktop] = useMediaQuery(['(min-width: 1200px)', '(min-width: 1600px)'])

    return (
        <>
            <Seo
                title="Logiciel Planning Clinique"
                description="Découvrez Staffea pour la gestion de vos plannings en clinique ➜ ✓ Installation rapide ✓ Création en 2H ✓ Gestion en temps réel ➜ Demandez une démo !"
            />

            <main>
                <MenuSticky>
                    <Section bg="indigo.100" id="clinical-staffea-section" name="staffea">
                        <Flex
                            pt={20}
                            pb={16}
                            gap={8}
                            align="center"
                            justify={['center', 'center', 'space-between']}
                            wrap="wrap"
                            maxW="1200px"
                            mx="auto"
                        >
                            <Flex
                                direction="column"
                                align="center"
                                mx="auto"
                                sx={{
                                    '@media (min-width: 1440px)': {
                                        maxW: '50%',
                                        marginX: 'revert',
                                        alignItems: 'start',
                                    },
                                }}
                            >
                                <Heading
                                    as="h1"
                                    fontWeight="bold"
                                    color="indigo.500"
                                    textAlign={['center', 'center', 'start']}
                                    mb={10}
                                >
                                    Logiciel de planning pour cliniques
                                </Heading>

                                <IconItemList list={features} icon={GrCheckmark} align="start" />

                                <Flex direction="column" w={['100%', '100%', '50%']} pt="12">
                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton
                                            text="Demander une démo"
                                            description="Démonstration de 45 minutes en visioconférence"
                                        />
                                    </a>
                                </Flex>
                            </Flex>

                            <Box
                                mx="auto"
                                sx={{
                                    '@media (min-width: 1440px)': {
                                        marginX: 'revert',
                                    },
                                }}
                            >
                                <MobileDesktopDisplay
                                    mobileContent={{
                                        image: (
                                            <Image
                                                src={imgMobilePlanning}
                                                alt="Staffea, application mobile écran de consultation du planning individuel"
                                            />
                                        ),
                                        description: 'A chaque salarié, son planning individuel !',
                                    }}
                                    desktopContent={{
                                        image: (
                                            <Image
                                                src={imgDesktopPlanning}
                                                alt="Staffea, application web écran de gestion du planning des managers"
                                            />
                                        ),
                                        description: 'Votre interface de gestion des plannings.',
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Section>

                    <Section bg="white" id="clinical-interoperability-section" name="interopérabilité">
                        <Box py={20}>
                            <GradientCard maxW="800px">
                                <Flex direction="column" alignItems="center" justifyContent="center" color="white">
                                    <Text as="h2" fontSize="lg" fontWeight="bold" align="center" mb={6}>
                                        Avec Staffea, pas d&#39;installation informatique complexe à prévoir.
                                        <br /> Notre solution se connecte à tous les logiciels RH !
                                    </Text>

                                    <Text fontSize="sm" align="center" px={[0, 0, 20]} mb={8}>
                                        Grâce à notre solution 100% web et mobile, utilisez le logiciel Staffea quand
                                        vous voulez et où vous voulez. Pas d&#39;installation compliquée, seul un accès
                                        internet est nécessaire pour créer vos plannings. Notre logiciel
                                        d&#39;organisation de planning s&#39;interface facilement à vos logiciels
                                        métiers et solutions RH en place.
                                        <br />
                                        <br />
                                        Notre équipe technique déploie Staffea à distance en moins de 48H, en intégrant
                                        vos dossiers administratifs et vos conventions collectives. Vous bénéficiez
                                        d&#39;une formation pour comprendre le fonctionnement du logiciel et être
                                        complètement autonome.
                                    </Text>

                                    <IconLink
                                        text="Voir le process d'intégration"
                                        to={`#clinical-onboarding-section`}
                                        icon={AiOutlineArrowDown}
                                        color="white"
                                        hoverColor="green.500"
                                    />
                                </Flex>
                            </GradientCard>
                        </Box>
                    </Section>

                    <Section bg="indigo.100" id="clinical-mobile-app-section" name="app mobile">
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                Une application mobile dédiée aux collaborateurs
                                <Text as="span" color="green.500" display="block">
                                    pour gagner en réactivité.
                                </Text>
                            </Text>

                            <VerticalCarousel
                                contents={verticalCarouselContent}
                                footer={
                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton
                                            height="14"
                                            text="Demander une démo"
                                            description="Démonstration de 45 minutes en visioconférence"
                                        />
                                    </a>
                                }
                            />
                        </Box>
                    </Section>

                    <Section bg="white" id="clinical-features-section" name="fonctionnalités">
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                Des fonctionnalités de planning précieuses
                                <Text as="span" color="green.500" display="block">
                                    pour économiser du temps.
                                </Text>
                            </Text>

                            <Flex my="12" gap="8" wrap="wrap" mx="auto">
                                {functionalities.map(({ id, media, title, content }) => (
                                    <HorizontalGridCard
                                        minH="170"
                                        key={id}
                                        media={media}
                                        title={title}
                                        content={content}
                                        maxW="485px"
                                        imagePadding={isDesktop ? '25' : '5'}
                                    />
                                ))}
                            </Flex>

                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" py={10}>
                                Les points forts de Staffea
                                <Text as="span" color="green.500" display="block">
                                    pour séréniser la gestion de planning en clinique.
                                </Text>
                            </Text>

                            <HorizontalCarousel
                                content={horizontalCarouselContent.map(({ title, description, image, alt }) => {
                                    return {
                                        title,
                                        description: (
                                            <Text color="indigo.500" fontSize="xs" align="start">
                                                {description}
                                            </Text>
                                        ),
                                        image: <Image key={uuidv4()} src={image} alt={alt} />,
                                    }
                                })}
                            />
                        </Box>
                    </Section>

                    <Section
                        id="clinical-onboarding-section"
                        name="onboarding"
                        backgroundImage={gradients}
                        footer={
                            <Section bg="indigo.500">
                                <Flex
                                    w="fit-content"
                                    mx="auto"
                                    py="6"
                                    gap={6}
                                    align="center"
                                    direction={['column', 'column', 'row']}
                                >
                                    <Text as="span" variant="inline" color="white" fontSize="sm" align="center">
                                        Staffea réduit <Text variant="bold">drastiquement</Text> le temps de saisie et
                                        de modification des plannings de vos équipes.
                                    </Text>

                                    <Icon
                                        as={FaLongArrowAltRight}
                                        color="white"
                                        w="6"
                                        h="6"
                                        display={['none', 'none', 'block']}
                                    />

                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton width="200px" hoverColor="green.500" text="Réserver une démo !" />
                                    </a>
                                </Flex>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={4}>
                                Un accompagnement technique
                                <Text as="span" color="green.500" display="block">
                                    pour vous aider à déployer Staffea dans votre clinique.
                                </Text>
                            </Text>

                            <Flow>
                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Récupération de vos données
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Créez automatiquement les profils de votre personnel médical en téléchargeant un
                                        fichier d&#39;import ou en utilisant notre API pour communiquer avec votre
                                        logiciel métier.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Configuration du planning
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        En 2 heures, créez et configurez vos plannings et vos roulements. Faites
                                        confiance à la suggestion automatique de Staffea pour les attribuer à vos
                                        collaborateurs. Si votre planning n&#39;est pas conforme, le logiciel le détecte
                                        et vous informe instantanément.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Formation au logiciel
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Vos planning managers bénéficient de 2 jours de formation pour apprendre à
                                        utiliser le logiciel de gestion des plannings en toute autonomie. En cas de
                                        besoin, une aide en ligne vous est fournie à tout moment.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Mise en service
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        A distance, la mise en service de Staffea est rapide : en moins de 48H ! Aucune
                                        installation n&#39;est requise, le logiciel est accessible avec un accès
                                        internet.
                                    </Text>
                                </Card>
                            </Flow>
                        </Box>
                    </Section>

                    <Section
                        bg="indigo.100"
                        id="clinical-mission-section"
                        name="mission"
                        footer={
                            <Section bg="white">
                                <Box py={20}>
                                    <Center textAlign="center">
                                        <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                            Vous soignez vos patients.
                                            <Text as="span" color="green.500" display="block">
                                                Nous soignons vos équipes !
                                            </Text>
                                        </Text>
                                    </Center>
                                    <Box mb="16">
                                        <Quote
                                            content="Pour accroître la qualité des soins et la satisfaction des patients de votre clinique, une planification rigoureuse est indispensable pour l'équipe médicale.
                                            Le logiciel Staffea vous fait entrer dans une démarche QVCT, centrée sur le bien-être de votre personnel médical. En plus d'un planning adapté à leurs besoins, ils sont protégés de la sur sollicitation et bénéficient d'un droit à la déconnexion. Les notifications de l'application mobile sont désactivables sur les jours de repos. 
                                            Les modifications d'horaires sont facilitées : vos collaborateurs peuvent solliciter des échanges avec des collègues directement sur l'application, en quelques clics. Vous passez, vous aussi, moins de temps à chercher des remplaçants ou à créer un planning satisfaisant pour tous. Plus de sérénité pour vous, et pour vos équipes sur le terrain !"
                                            author="Mathieu MOSQUET - Cofondateur de Staffea"
                                            nbCol={2}
                                        />
                                    </Box>
                                </Box>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Center textAlign="center">
                                <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                    Une solution adaptée
                                    <Text as="span" color="green.500" display="block">
                                        à toutes les professions en clinique.
                                    </Text>
                                </Text>
                            </Center>

                            <Text color="indigo.500" mb={16}>
                                Développez le bien-être au travail de vos médecins, infirmiers, aides-soignants,
                                sages-femmes et pharmaciens grâce au logiciel de planning pour clinique Staffea. Leur
                                métier est difficile et un planning bien organisé, en fonction de leurs préférences,
                                peut considérablement améliorer leur qualité de vie au travail. <br /> <br /> Staffea
                                est le logiciel idéal pour organiser les meilleurs horaires possibles pour chaque
                                profession médicale, avec l&#39;appui de l&#39;intelligence artificielle et du machine
                                learning. Vos plannings et roulements sont prêts en moins de 2H, vous n&#39;avez plus
                                qu&#39;à faire confiance au logiciel pour leur attribution. Gagnez en sérénité !
                            </Text>
                        </Box>
                    </Section>
                </MenuSticky>
            </main>
        </>
    )
}

export default ClinicalPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["ehpad", "404"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
